import { $getCookie, $getQueryParam } from '@/helpers'
import { useEffectiveSubmitTrackStore } from '@/stores'
import { sleep } from '@/utils'

type TAttributionType = 'tt' | 'fb'
/**
 * 通用Cookie轮询函数
 * @param {string[]} cookieNames - 需要获取的Cookie名称数组
 * @param {number} [maxRetries=20] - 最大重试次数（默认20）
 * @param {number} [retryInterval=100] - 重试间隔毫秒数（默认100）
 * @returns {Promise<Object>} - 包含Cookie键值对的对象（未获取到的值为undefined）
 */
async function pollCookies(cookieNames: string[], maxRetries = 20, retryInterval = 100) {
  const results: any = {}
  for (let i = 0; i < maxRetries; i++) {
    cookieNames.forEach((name) => {
      if (!results[name])
        results[name] = $getCookie(name) || undefined
    })
    if (cookieNames.every(name => results[name] !== undefined))
      break
    if (i < maxRetries - 1)
      await sleep(retryInterval)
  }

  return results
}

export default function useFacebookTrack() {
  const { $attribution, $setParams } = useEffectiveSubmitTrackStore()

  const collectTTParams = (ttclid: string, _ttp: string) => {
    const params = {
      _fbc: ttclid,
      _fbp: _ttp,
      attributeType: 'web',
      url: window.location.href,
      ua: navigator.userAgent,

      utm_campaign: $getQueryParam('utm_campaign') || '',
      utm_adset: $getQueryParam('utm_adset') || '',
      utm_campaign_id: $getQueryParam('utm_campaign_id') || '',
      utm_adset_id: $getQueryParam('utm_adset_id') || '',
      utm_ad_id: $getQueryParam('utm_ad_id') || '',
      utm_source: $getQueryParam('utm_source') || '',
      utm_site_source_name: $getQueryParam('utm_site_source_name') || '',
      utm_medium: $getQueryParam('utm_medium') || '',
      utm_ad_name: $getQueryParam('utm_ad_name') || '',
      contentId: $getQueryParam('contentId') || '',
      initIndex: $getQueryParam('initIndex') || '',
      pId: $getQueryParam('pId') || '',
    }

    $attribution()
    $setParams(params)
  }

  const collectFBParams = (_fbp: any, _fbc: any) => {
    const params = {
      _fbp,
      _fbc,
      attributeType: 'web',
      url: window.location.href,
      ua: navigator.userAgent,

      pId: $getQueryParam('pId') || '',
      contentId: $getQueryParam('contentId') || '',
      initIndex: $getQueryParam('initIndex') || '',
      utm_campaign_id: $getQueryParam('utm_campaign_id') || '',
      utm_campaign: $getQueryParam('utm_campaign') || '',
      utm_adset: $getQueryParam('utm_adset') || '',
      utm_adset_id: $getQueryParam('utm_adset_id') || '',
      utm_ad_name: $getQueryParam('utm_ad_name') || '',
      utm_ad_id: $getQueryParam('utm_ad_id') || '',
      utm_source: $getQueryParam('utm_source') || '',
      utm_medium: $getQueryParam('utm_medium') || '',
      utm_site_source_name: $getQueryParam('utm_site_source_name') || '',
      utm_id: $getQueryParam('utm_id') || '',
      utm_content: $getQueryParam('utm_content') || '',
      utm_term: $getQueryParam('utm_term') || '',
    }

    $attribution()
    $setParams(params)
  }

  return {
    async init() {
      let attributionType: TAttributionType | undefined
      if ($getQueryParam('utm_source') === 'tt' && $getQueryParam('ttclid'))
        attributionType = 'tt'
      if ($getQueryParam('utm_source') === 'fb' && $getQueryParam('fbclid'))
        attributionType = 'fb'

      if (attributionType === 'tt') {
        const results = await pollCookies(['_ttp', 'ttclid'])
        // eslint-disable-next-line no-console
        console.info('results', results)
        collectTTParams(results.ttclid, results._ttp)
      }

      if (attributionType === 'fb') {
        const results = await pollCookies(['_fbp', '_fbc'])
        // eslint-disable-next-line no-console
        console.info('results', results)
        collectFBParams(results._fbp, results._fbc)
      }
    },
  }
}

// tt 链接
// https://h5-fb-test.rocnovel.com/content/228211894582247424/?
// utm_campaign=0313-test
// &utm_adset=0313test
// &utm_campaign_id=1826471701030929
// &utm_adset_id=1826471701030961
// &utm_ad_id=1826471704216625
// &utm_source=tt
// &utm_site_source_name=tt
// &utm_medium=tt
// &utm_ad_name=1_HGDQM4Zu.mp4_2025-03-13%2017%3A30%3A21
// &contentId=228211894582247424
// &initIndex=null
// &pId=231115327815557120
// &ttclid=E_C_P_CsEBuiB9Z7Tl5oEujM4EW7KLVr8lMm_0BQWd-5U9sv-1OjDqq8Ls-e2cYogb5dVaCCkk0_1mWvqMPuj0-3iqquh8EURMnMXbW7UjXTd6JtytI1K8xY14yn5K3zEk5Zd0lV_xRbT6h--bYRoeiZMFaVFr-eeHIulJ-xb7MOxDI5N1EOwGtE56BOo5fYcfH2n-EdgU3LiiMXMrkB8mOtMU5sDC0s6xsIVD_xE7VxMZ_bIBxmjz69ioRyubz2cJYGVlMadLDhIEdjIuMA

// fb 链接
// https://h5-fb-test.rocnovel.com/content/190052388312711168/?
// utm_campaign=Panda_rocnovel_AND_182899821023596544_HYJ03_1125_1014V_test
// &utm_adset=V
// &utm_campaign_id=120213965296190162
// &utm_medium=Facebook_Desktop_Feed
// &utm_adset_id=120213965296440162
// &utm_ad_id=120213965296800162
// &utm_source=fb
// &utm_ad_name=1125-test
// &utm_site_source_name=fb
// &contentId=190052388312711168
// &initIndex=null
// &pId=191922336765050880
// &utm_id=120213965296190162
// &utm_content=120213965296800162
// &utm_term=120213965296440162
// &fbclid=IwY2xjawGxECFleHRuA2FlbQIxMAABHftuWjJ_vybW7NIPAybd0LCDJHwywHTlt_PqpM0YK9gjKRYPBZaGhFgvOA_aem_JN3DZ4ShLxryc8Jdau1ECA
