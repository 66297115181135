import VueRouter from 'vue-router'
import { v4 as uuid } from 'uuid'
import useAttribution from '../src/composables/useAttribution'
import { ERROR_PAGE_LEVEL } from './constants'
import { useEffectiveSubmitTrackStore, useHistoryStore, useUserStore } from '@/stores'
import { $getDeviceId, $setDeviceId, $setToken } from '@/helpers'
import routes from '@/routes'
import './ployfills'
import { isInThirdAppBrowser } from '@/utils/bowserEnv'

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior(_to, _from, _savedPosition) {
    return { x: 0, y: 0, behavior: 'smooth' }
  },
})

// Router Guards
router.beforeEach(async (to, form, next) => {
  // Set document title
  document.title = to?.meta?.title
  const userStore = useUserStore()

  // 无须登陆
  if (to.meta?.requiresAuth === false) {
    next()
  }
  // 三方绑定
  else if (to.query && to.query.t) {
    $setToken(to.query.t as string)
    const query = { ...to.query }
    delete query.t
    await userStore.init()
    next({
      ...to,
      name: to.name ?? undefined,
      query,
      replace: true,
    })
  }
  /**
   * 内部浏览器引流到外部浏览器
   * bt是原 token
   * a 是是否已经激活过
   */
  else if (to.query && to.query.bt) {
    if (isInThirdAppBrowser())
      return

    const query = { ...to.query }
    if (to.query.a) {
      const { $attribution } = useEffectiveSubmitTrackStore()
      $attribution()
      delete query.t
    }
    await userStore.transferLogin(to.query.bt as string)
    delete query.bt
    next({
      ...to,
      name: to.name ?? undefined,
      query,
      replace: true,
    })
  }
  // 首次登录
  else if (to.meta?.requiresAuth !== false && !userStore.isLogin()) {
    let deviceUuid = $getDeviceId()
    let isNewUser = false
    if (!deviceUuid) {
      deviceUuid = uuid()
      $setDeviceId(deviceUuid)
      isNewUser = true
    }
    // 先收集归因信息, 再登录
    await useAttribution().init()
    await userStore.login(deviceUuid, isNewUser)
    next()
  }
  // 已登录
  else {
    try {
      await userStore.init()
    }
    catch (error: any) {
      console.error(error)
      next({
        name: 'error',
        query: {
          level: ERROR_PAGE_LEVEL.DEFAULT,
          message: error.msg || error.message,
          redirect: window.encodeURIComponent(window.location.href),
        },
        replace: true,
      })
    }
    next()
  }
})

// Tracking
router.afterEach((to, from) => {
  const params: Record<string, any> = {
    fromUrl: from.fullPath,
    fromTitle: from.meta?.title ?? '',
    toUrl: to.fullPath,
    toTitle: to.meta?.title ?? '',
  }
  if (to.name === 'book') {
    params.contentId = to.params.contentId
  }
  else if (to.name === 'chapter') {
    params.contentId = to.params.contentId
    params.chapterId = to.params.chapterId
    if (to.query.finishRecommend)
      params.toTitle = '文末推荐'
  }
  else if (to.name === 'activity') {
    params.activityId = to.params.activityId
  }
})

// Store the route stack
router.afterEach((to, from) => {
  const historyStore = useHistoryStore()
  if (['authorize', 'paying'].includes(to.name ?? '')) {
    // Skip record
  }
  else if (to.name === 'history' && to.query.from === 'member') {
    historyStore.$push(to.fullPath)
  }
  // Note: same route || tabs route
  else if (to.name === from.name || ['history', 'home', 'mm', 'category', 'member'].includes(to.name ?? '')) {
    historyStore.$replace(to.fullPath)
  }
  else {
    historyStore.$push(to.fullPath)
  }
})

export default router
