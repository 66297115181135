import { ref } from 'vue'
import { api } from '@/api'
import { getDeviceFeature } from '@/utils/deviceFeature'

export const bannerShow = ref(false)

export function useBannerShow() {
  if (!bannerShow.value) {
    const matchLayer = api.post(`/member/layer/match?layerConfigIdStr=${import.meta.env.VITE_BIZ_SHOWBANNER_LAYER_ID}`)
    const matchAd = api.get('/member/ad/info')

    Promise.all([matchLayer, matchAd]).then((res) => {
      bannerShow.value = !!res[0].data && !!res[1].data?.displayDirectToApp
    })
  }
}

export function useCallRocnovelApp() {
  async function callApp(id?: string, index?: number) {
    const deviceFeature = await getDeviceFeature()
    const token = localStorage.getItem('TOKEN')
    let target
    let copyStr

    if (id && index) {
      target = `/read?id=${id}&index=${Number(index) - 1}`
      copyStr = `#rocnovel#${token}#${target}`
    }
    else if (id) {
      target = `/book_detail?id=${id}`
      copyStr = `#rocnovel#${token}#${target}`
    }
    else {
      target = ''
      copyStr = `#rocnovel#${token}`
    }

    try {
      navigator.clipboard.writeText(copyStr).then(async () => {
        await api.post('/member/deviceFeature', { deviceFeature })
        // eslint-disable-next-line no-console
        console.info('target', target)
        // eslint-disable-next-line no-console
        console.info('copyStr', copyStr)
        window.location.href = `https://h5.rocnovel.com/rocnovel/app?target=${encodeURIComponent(target)}#/rocnovel/app`
      })
    }
    catch (err) {
      console.error('复制失败:', err)
      window.location.href = `https://h5.rocnovel.com/rocnovel/app?target=${encodeURIComponent(target)}#/rocnovel/app`
    }
  }

  return {
    callApp,
  }
}
