import { initializeApp } from 'firebase/app'
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  getAuth,
  getRedirectResult,
  signInWithRedirect,
  signOut,
} from 'firebase/auth'
import * as Sentry from '@sentry/vue'
import { computed, onMounted, onUnmounted } from 'vue'
import { useSessionStorage } from '@vueuse/core'
import { useRouter } from 'vue-router/composables'
import { firebaseConfig } from './firebaseConfig'
import { useToastStore } from '@/stores'
import { api } from '@/api'
import { $formatErrorMessage, $getDeviceId, $getToken, $setToken } from '@/helpers'

// 临时存储
const loginTypeTemp = useSessionStorage<any>('loginTypeTemp', undefined)
export function useTripartiteLogin(deviceUuid: string, loginToken: string, redirect: string) {
  initializeApp(firebaseConfig)
  const auth = getAuth()
  const skeleton = computed(() => !!loginTypeTemp.value)
  const { $toast } = useToastStore()
  const router = useRouter()

  onMounted(() => {
    // 往返缓存
    window.addEventListener('pageshow', (e) => {
      if (e.persisted)
        loginTypeTemp.value = undefined
    })
  })

  onUnmounted(() => {
    loginTypeTemp.value = undefined
  })

  // 只有在授权回来时, result才有值
  getRedirectResult(auth)
    .then((result: any) => {
      // eslint-disable-next-line no-console
      console.info('result', result)
      if (!result) {
        loginTypeTemp.value = undefined
        return
      }

      login(loginTypeTemp.value, result.user.providerData)
    }).catch((error) => {
      const errorCode = error.code
      const errorMessage = error.message
      const email = error.customData.email
      const credential = FacebookAuthProvider.credentialFromError(error)

      Sentry.captureEvent({
        message: 'TripartiteLoginError',
        level: 'info',
        extra: {
          errorCode,
          errorMessage,
          email,
          credential,
        },
      })
    })

  async function signInWithGoogle() {
    const providerData = auth?.currentUser?.providerData || []
    const lastLoginHasFacebook = providerData?.filter((item: any) => item.providerId === 'google.com') || []

    if (lastLoginHasFacebook.length > 0) {
      login('google.com', lastLoginHasFacebook)
    }
    else {
      loginTypeTemp.value = 'google.com'
      const provider = new GoogleAuthProvider()
      signInWithRedirect(auth, provider)
    }
  }

  async function signInWithFacebook() {
    const providerData = auth?.currentUser?.providerData || []
    const lastLoginHasFacebook = providerData?.filter((item: any) => item.providerId === 'facebook.com') || []

    if (lastLoginHasFacebook.length > 0) {
      login('facebook.com', lastLoginHasFacebook)
    }
    else {
      loginTypeTemp.value = 'facebook.com'
      const provider = new FacebookAuthProvider()
      provider.addScope('email,public_profile')
      signInWithRedirect(auth, provider)
    }
  }

  async function logout() {
    if (auth && auth.currentUser)
      signOut(auth)
  }

  function getLoginType(type: 'facebook.com' | 'google.com') {
    if (type === 'facebook.com')
      return '2'

    else if (type === 'google.com')
      return '1'
  }

  async function login(type: 'facebook.com' | 'google.com', providerData: any) {
    const params = {
      thirdType: getLoginType(type),
      thirdPartId: '',
      nickName: '',
      email: '',
      headImage: '',
    }
    providerData.forEach((item: any) => {
      if (item.providerId === type) {
        params.thirdPartId = item.uid
        params.nickName = item.displayName
        params.email = item.email
        params.headImage = item.photoURL
      }
    })
    try {
      const res = await api.post<PostMemberBindThirdPartBody, PostMemberBindThirdPartResponse>('/member/bind/thirdPart', params, {
        headers: {
          equipmentId: deviceUuid,
          token: loginToken,
        },
      })
      if (res.code === 0) {
        if (redirect) {
          const url = new URL(redirect)
          url.searchParams.set('t', res.data.token)
          window.location.replace(url.toString())
        }
        else {
          $setToken(res.data.token)
          router.replace({ name: 'member' })
        }
      }
    }
    catch (error) {
      $toast($formatErrorMessage(error))
    }

    setTimeout(() => {
      loginTypeTemp.value = undefined
    }, 3000)
  }

  return {
    skeleton,
    signInWithGoogle,
    signInWithFacebook,
    logout,
  }
}

export function tripartiteLoginPage() {
  const d = $getDeviceId()
  window.location.href = `${import.meta.env.VITE_CDN}/login?redirect=${encodeURIComponent(window.location.href)}&d=${d}&t=${$getToken()}`
  // window.location.href = `https://h5-fb-local.rocnovel.com/login?redirect=${encodeURIComponent(window.location.href)}&d=${d}`
}
