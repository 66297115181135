<script lang="ts" setup>
import { useIntersectionObserver } from '@vueuse/core'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { asyncTrack, track } from '@/track'

const props = withDefaults(defineProps<{
  isReportListImp?: boolean
  extra?: Record<string, any>
  id: string
}>(), {
  isReportListImp: true,
})

const isVisible = ref(false)
const ob = ref<any>(null)
const alreadyExposed: string[] = []

// 书曝光
onMounted(() => {
  if (!props.isReportListImp)
    return

  ob.value = useIntersectionObserver(
    document.getElementById(props.id),
    ([{ isIntersecting }]) => {
      isVisible.value = isIntersecting
      if (isIntersecting && !alreadyExposed.includes(props.id)) {
        asyncTrack('listImp', {
          module_name: props.extra?.module_name || '',
          module_id: props.extra?.module_id || '',
          module_type: props.extra?.module_type || '',
          book_id: props.extra?.id || '',
          book_name: props.extra?.contentName || '',
          page_id: props.extra?.pageId || '',
        })

        alreadyExposed.push(props.id)
      }
    },
    {
      root: null,
      rootMargin: '0px',
      threshold: [1],
    },
  )
})

onBeforeUnmount(() => {
  if (ob.value && props.isReportListImp)
    ob.value.stop()
})

// 书点击
function onClick() {
  track('listClick', {
    module_name: props.extra?.module_name || '',
    module_id: props.extra?.module_id || '',
    module_type: props.extra?.module_type || '',
    book_id: props.extra?.id || '',
    book_name: props.extra?.contentName || '',
  })
}
</script>

<template>
  <div :id="id" @click.capture="onClick">
    <slot />
  </div>
</template>
