<script lang="ts" setup>
import { computed, onMounted, provide, ref, watch } from 'vue'
import { useRouter } from 'vue-router/composables'
import { api } from '@/api'
import { $loadTextFromCDN } from '@/helpers'
import { useToastStore } from '@/stores'
import { bannerShow, useBannerShow } from '@/composables/useBannerShow'
import { useGotoBack } from '@/composables'

const props = defineProps<{
  contentId: string
}>()

const containerRef = ref<HTMLElement | null>(null)
provide('containerRef', containerRef)

const router = useRouter()
const { $toast } = useToastStore()
const gotoBack = useGotoBack()

const skeleton = ref(false)
const book = ref<GetContentGetcontentdetailResponse['data']>()
const sample = ref<string>('')
const paragraphs = computed(() => {
  if (!sample.value)
    return []
  return sample.value.split(/[\n]/).filter(Boolean)
})
async function fetchBook() {
  try {
    skeleton.value = true
    const { data } = await api.get<any, GetContentGetcontentdetailResponse>('/content/getContentDetail', { params: { contentId: props.contentId } })
    book.value = data
    if (data.firstChapterContentUrl)
      sample.value = await $loadTextFromCDN(data.firstChapterContentUrl)
    else
      sample.value = '暂无样章'
  }
  finally {
    skeleton.value = false
  }
}
onMounted(async () => {
  fetchBook()
  useBannerShow()
})
watch(() => props.contentId, () => {
  fetchBook()
})

async function onRead() {
  if (!book.value?.firstChapter)
    return $toast('暂无章节')

  router.push({ name: 'chapter', params: { contentId: book.value!.id, chapterId: book.value.firstChapter!.id } })
}

// Popup: TOC
const popupTOC = ref(false)
</script>

<template>
  <div v-if="skeleton">
    <div class="pt-20vh flex justify-center">
      <BasicSpinner class="mx-auto" />
    </div>
    <p class="mt-4 text-center font-bold">
      Loading...
    </p>
  </div>
  <div v-else-if="!book">
    <BizBlockEmpty title="Books don't exist." />
  </div>
  <div v-else ref="containerRef" class="h-screen pb-20 bg-white overflow-scroll">
    <BizDownloadApp
      v-if="bannerShow"
      :id="contentId"
      position="fixed"
      class="fixed top-0 w-full z-9999"
    />
    <!-- Cover -->
    <div
      class="cover pt-44px pb-44px relative"
      :class="{ 'mt-60px': bannerShow }"
    >
      <div
        class="w-full h-full absolute top-0 left-0 !bg-cover !bg-no-repeat"
        :style="`background: url(${book.contentCoverUrl})`"
      >
        <div class="absolute top-12px left-12px text-24px z-99" @click="gotoBack()">
          <i-icon-park-outline-left
            class="text-24px"
          />
        </div>
        <div class="h-full bg-white opacity-70" />
        <div class="w-full h-full absolute left-0 top-0" style="backdrop-filter: blur(5px);" />
      </div>
      <div class="text-center z-1 relative">
        <img class="w-25 h-140px mx-a rounded-3px" :src="book.contentCoverUrl" alt="图片:书封">
        <p class="mt-5 text-18px font-bold">
          {{ book.contentName }}
        </p>
        <p class="mt-5px text-13px text-#333">
          {{ book.finishStatus ? 'Completed' : 'Ongoing' }}｜{{ book.authorInfo?.author }}
        </p>
      </div>
    </div>
    <!-- Summary -->
    <div
      class="-mt-8 mx-5 px-5 py-15px bg-white rounded relative"
      style="box-shadow: 0px 40px 90px 0px #0000000D;"
    >
      <div class="-mb-10px flex items-center justify-center space-x-10px flex-wrap text-13px">
        <span v-for="tag in book.tagList" :key="tag.id" class="mb-10px px-5px py-3px bg-#E2FCFB rounded-2px">{{ tag.name }}</span>
      </div>
      <div class="py-10px flex items-center space-x-10px">
        <i-icon-park-outline-quote class="w-6 h-6 text-#FF7D004D" />
        <hr class="flex-1 border-#FF7D004D">
      </div>
      <p class="text-14px lh-22px">
        {{ book.introduce }}
      </p>
      <div class="flex justify-end">
        <i-icon-park-outline-quote class="w-6 h-6 text-#FF7D004D rotate-180" />
      </div>
    </div>
    <!-- Sample -->
    <div class="mt-5 mx-5 pb-5">
      <div class="flex space-x-4 items-center justify-between">
        <p class="text-17px font-bold whitespace-nowrap">
          Catalogue
        </p>
        <div class="w-70vw">
          <div class="text-13px text-#999 flex items-center justify-end" @click="popupTOC = true">
            <p class="line-clamp-1">
              Latest chapter：{{ book.lastChapter?.chapterName }}
            </p>
            <i-icon-park-outline-right class="w-4 h-4" />
          </div>
        </div>
      </div>
      <div class="lh-30px">
        <p class="mt-4">
          {{ book.firstChapter?.chapterName }}
        </p>
        <p v-for="(item, index) in paragraphs.slice(0, 16)" :key="index" class="mt-4">
          {{ item }}
        </p>
      </div>
      <div class="mt-10 flex items-center justify-center space-x-2 text-#999">
        <span class="w-8 h-1px bg-#999" />
        <span class="text-14px">Read the first chapter first</span>
        <span class="w-8 h-1px bg-#999" />
      </div>
    </div>

    <BizPopupBookTOC
      v-model="popupTOC"
      :book="book" :content-id="book.id" :chapter-id="book.firstChapter?.id"
    />

    <BasicFixedBottom style="box-shadow: 0px -6px 60px 0px rgba(0, 0, 0, 0.10);">
      <BizTrackClick name="read">
        <div class="w-full px-5 pt-3 pb-0 bg-white" @click="onRead()">
          <a class="block w-full h-45px lh-45px text-center text-15px text-white font-bold bg-primary rounded">
            {{ !book.readFlag ? 'Start Reading' : 'Continue Reading' }}
          </a>
        </div>
      </BizTrackClick>
    </BasicFixedBottom>
  </div>
</template>

<style scoped>
.cover {
  background-size: cover !important;
}
</style>
