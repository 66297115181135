import { UAParser } from 'ua-parser-js'

export async function getDeviceFeature() {
  const uap = new UAParser()
  const result = uap.getResult()
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const language = window.navigator.language

  let str = ''
  if (result.os.name === 'Android') {
    const androidRes = await uap.getResult()?.withClientHints()
    str = `#${androidRes.device.model}#${androidRes.device.type}#${androidRes.device.vendor}#${androidRes.os.name}#${timezone}#${language}`
  }
  else if (result.os.name === 'iOS') {
    str = `#${result.device.model}#${result.device.type}#${result.device.vendor}#${result.os.name}#${result.os.version}#${timezone}#${language}#${window.screen.width}#${window.screen.height}`
  }

  return str
}
