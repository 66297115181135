import axios from 'axios'
import { $getDeviceId, $getToken, $removeToken } from '@/helpers'
import { useToastStore } from '@/stores'

let apiDomain = import.meta.env.VITE_API_DOMAIN
export const api = axios.create({
  baseURL: apiDomain + import.meta.env.VITE_API_BASE,
  responseType: 'json',
})
export function updateApiDomain(domain: string) {
  apiDomain = domain
}

api.interceptors.request.use((options) => {
  options.headers.Platform = 3
  options.headers.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // Skip `development` mode, Because of CORS
  if (import.meta.env.MODE !== 'development')
    options.baseURL = apiDomain + import.meta.env.VITE_API_BASE

  const token = options.headers?.token || $getToken()
  if (token)
    options.headers.Authorization = token

  const equipmentId = options.headers?.equipmentId || $getDeviceId()
  if (equipmentId)
    options.headers.equipmentId = equipmentId

  options.params = {
    ...options.params,
  }

  return options
})

api.interceptors.response.use((response) => {
  // 用户 TOKEN 过期
  if (response.data.code === 401) {
    useToastStore().$toast('User info has expired, the page is about to refresh.')
    $removeToken()

    setTimeout(() => {
      window.location.replace('/')
    }, 2000)
    return
  }

  if (response.data.code !== 0)
    return Promise.reject(response.data)

  return response.data
})
