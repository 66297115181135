<script lang="ts" setup>
import { computed, nextTick, ref, watch } from 'vue'
import Swiper from 'swiper'
import { Autoplay, Pagination } from 'swiper/modules'
import { useUserStore } from '@/stores'
import 'swiper/css'
import 'swiper/css/pagination'

import { usePurchase } from '@/composables/usePurchase'
import { useSubscript } from '@/composables/useSubscript'
import { SubscriptionCycle } from '@/utils/enum'
import SwiperBg from '@/assets/member_subscribe_bg.png'
import { VIP_TYPE } from '@/constants'

const props = defineProps<{
  value: boolean
  contentId: string
  contentChapterId: string
  bookName: string
  chapterName: string
  serialStatus: number
}>()

const emit = defineEmits<{
  (event: 'input', value: boolean): void
  (event: 'getPayUrlSuccess'): void
}>()

const userStore = useUserStore()
const { templateProducts, PayTemplateSuccess, PayTemplate } = usePurchase({
  contentId: props.contentId,
  contentChapterId: props.contentChapterId,
  bookName: props.bookName,
  chapterName: props.chapterName,
  serialStatus: props.serialStatus,
})
const { loaded, subscribeProducts, subscribeSuccess, getUnitPrice, onSubscribeProducts } = useSubscript({
  contentId: props.contentId,
  contentChapterId: props.contentChapterId,
})
const active = ref(0)

const subscribes = computed(() => {
  return subscribeProducts.value.filter(item => item.purchasePopShow)
})

function handlePayTemplate(item: any, index: number) {
  active.value = index
  PayTemplate(item)
}

function initSwiper() {
  nextTick(() => {
  // eslint-disable-next-line no-new
    new Swiper('.banner.swiper', {
      modules: [Pagination, Autoplay],
      spaceBetween: 0,
      autoplay: {
        delay: 3500,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      loop: true,
    })
  })
}

watch(
  () => loaded.value,
  (val) => {
    if (val)
      initSwiper()
  },
)

watch(
  () => subscribeSuccess.value,
  (val) => {
    if (val)
      emit('getPayUrlSuccess')
  },
)

watch(
  () => PayTemplateSuccess.value,
  (val) => {
    if (val)
      emit('getPayUrlSuccess')
  },
)
</script>

<template>
  <BasicPopup :value="value" @input="value => $emit('input', value)">
    <div class="popup-recharge w-full px-5 pt-20px pb-30px bg-white rounded-t-lg relative">
      <h3 class="text-18px text-center">
        Chapter is locked
      </h3>
      <p class="text-14px text-#65686F text-center">
        Choose a recharge level and unlock chapters
      </p>
      <!-- 支付 -->
      <div class="flex flex-wrap justify-between">
        <div
          v-for="(item, index) in templateProducts"
          :key="index"
          class="relative border b-rd-8px w-48% h-70px mt-4 flex flex-col justify-center items-center text-#65686F"
          :style="{
            border: active === index ? '1px solid #9875FB' : '1px solid #F3F5FA',
            background: active === index ? '#F3EFFF' : '#F3F5FA',
          }"
          @click="handlePayTemplate(item, index)"
        >
          <div
            v-if="item.marketingText"
            class="pos-absolute text-#fff left--1px top--8px min-w-50px h-16px text-10px py-2px px-2 flex items-center"
            style="background: linear-gradient( 132deg, #F961BC 0%, #F74984 100%); border-radius: 100px 100px 100px 0px;"
          >
            {{ item.marketingText }}
          </div>
          <div>
            <span class="text-18px font-medium text-#32353A">{{ item.coinsBuy }}+{{ item.coinsBonus }}</span> coins
          </div>
          <div class="mt-2px">
            $ {{ item.price }}
          </div>
        </div>
      </div>

      <!-- 订阅 -->
      <div v-if="!userStore.$state.subscribe && subscribes?.length" class="banner swiper mt-4">
        <div class="swiper-wrapper ">
          <div
            v-for="(item, index) in subscribes"
            :key="index"
            class="swiper-slide SwiperBg swiper-item"
            :style="`background: url('${SwiperBg}') no-repeat center center;background-size: 100% 100%;`"
          >
            <div v-if="item.subscribeType === VIP_TYPE.DISCOUNT" class="absolute font-bold top-0 right-0 text-32px  leading-[30px] text-[#9875FB]">
              {{ item.cycleStr }}
              <span class="absolute top-0 right-0 w-105% h-130% cycleBg" />
            </div>
            <div class="relative w-full h-full flex items-center px-12px pt-20px pb-20px text-#32353A" @click="onSubscribeProducts(item)">
              <div v-if="item.cornerTip && item.marketingCopy" class="popular-tag">
                {{ item.marketingCopy }}
              </div>
              <div class="flex-shrink-0">
                <img v-if="item.subscribeType === VIP_TYPE.DISCOUNT" src="@/assets/vip_discount.png" width="22">
                <img v-else src="@/assets/vip_icon2.png" width="22">
              </div>
              <div v-if="item.subscribeType !== VIP_TYPE.DISCOUNT" class="flex-1 ml-8px flex flex-col justify-start">
                <div class="text-18px font-medium">
                  {{ SubscriptionCycle.getItemByValue(item.cycle)?.label }}
                </div>
                <div class="text-#9875FB truncate-2-lines">
                  {{ item.subscribeText }}
                </div>
              </div>
              <div v-else class="flex-1 ml-16px">
                <div class="text-18px font-medium">
                  {{ item.discountCoins }} Coins
                </div>
                <ul class="text-#9875FB text-12px list-disc pl-5">
                  <li>Get {{ item.discountOnceCoins }} Coins at once</li>
                  <li>Earn {{ item.discountBonusCoins }} Coins ({{ item.discountDailyCoins }}/day) from daily login</li>
                </ul>
              </div>
              <div class="flex-shrink-0 text-right relative">
                <span v-if="item.subscribeType === VIP_TYPE.DISCOUNT" class="absolute top-[-1px] left-[0px] w-180% h-115px text-bg z-[-1]" />

                <div class="text-18px font-bold">
                  $ {{ item.price }}
                </div>
                <div v-if="getUnitPrice(item) && item.subscribeType !== VIP_TYPE.DISCOUNT" class="text-#65686F text-12px">
                  $ {{ getUnitPrice(item) }}
                </div>
                <div v-else-if="item.subscribeType === VIP_TYPE.DISCOUNT && item.discountOriginalPrice" class="text-#65686F text-12px line-through">
                  $ {{ item.discountOriginalPrice }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination flex items-center justify-center" />
      </div>

      <div v-if="!loaded" class="h-260px flex items-center justify-center flex-col">
        <BasicSpinner class="mx-auto" />
        <div class="mt-2 font-bold">
          loading...
        </div>
      </div>
    </div>
  </BasicPopup>
</template>

<style lang="scss" scoped>
.pay-template-info:hover {
  border: 1px solid #9875FB;
  background-color: #F3EFFF;
}
.swiper-item{
  height: 115px;
}
@media screen and (min-width: 380px) {
  .swiper-item{
    min-height: 91px;
  }
}
.cycleBg {
  background-image: linear-gradient(
    180deg,
    #bea9ff00,
    #f3efff
  );
  font-family: HelveticaNeue-Bold;
}
.text-bg {
  background-image: linear-gradient(
    180deg,
    #f3efff,
    #bea9ff00,
  );
}
</style>

<style lang="scss">
.popup-recharge {
  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    background: #ffffff;
    border-radius: 3px;
    opacity: 0.5;
  }

  .swiper-pagination-bullet-active {
    width: 20px;
    height: 6px;
    background: #ffffff;
    border-radius: 3px;
    opacity: 1;
  }

  .popular-tag {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 56px;
    height: 18px;
    background: linear-gradient( 132deg, #F961BC 0%, #F74984 100%);
    border-radius: 8px 0px 12px 0px;
    font-weight: 400;
    font-size: 10px;
    color: #FFFFFF;
    text-align: center;
  }

  .truncate-2-lines {
    width: 100%;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 这里是超出几行省略 */
    overflow: hidden;
  }
}
</style>
