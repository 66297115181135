<script lang="ts" setup>
import { computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { useSessionStorage } from '@vueuse/core'
import { useHistoryStore } from '@/stores'
import { useTripartiteLogin } from '@/composables/useTripartiteLogin'
import { useEmailLogin } from '@/composables/useEmailLogin'

const route = useRoute()
const router = useRouter()
const historyStore = useHistoryStore()
const showLogout = localStorage.getItem('logout')
const deviceUuid = useSessionStorage<any>('did', undefined)
const redirect = useSessionStorage<any>('redirect', undefined)
const loginToken = useSessionStorage<any>('loginToken', undefined)

const redirectUrl = route.query.redirect as string
// d 是用户的设备id
const d = route.query.d as string
// t 是要登录用户的 token
const t = route.query.t as string

const init = function () {
  if (redirectUrl)
    redirect.value = redirectUrl

  const query = { ...route.query }
  if (d) {
    deviceUuid.value = d
    delete query.d
  }

  if (t) {
    loginToken.value = t
    delete query.t
  }

  router.replace({
    query: { ...query },
  })
}
init()
const { skeleton, signInWithGoogle, signInWithFacebook, logout } = useTripartiteLogin(deviceUuid.value, loginToken.value, redirect.value)

const isEmailLogin = computed({
  get: () => ~~route.query.emailLogin,
  set: (v: any) => (route.query.emailLogin = `${v}`),
})

function setEmailLogin() {
  historyStore.$push(window.location.href)
  router.push({
    query: {
      ...route.query,
      emailLogin: '1',
    },
  })
}

const back = function () {
  window.history.go(-1)
}

const { form, isRightEmail, getCode, beforeSignIn, resetEmail } = useEmailLogin(deviceUuid.value, loginToken.value, redirect.value)

watch(
  () => isEmailLogin.value,
  () => resetEmail(),
)
</script>

<template>
  <div class="login-page">
    <div v-if="!skeleton" class="login-bg">
      <div class="fixed top-32px left-12px" @click="back()">
        <i-icon-park-outline-left class="w-6 h-6" />
      </div>

      <!-- 登录首页 -->
      <div v-if="!isEmailLogin" class="mt-180px flex flex-col items-center font-medium">
        <img src="@/assets/login/roc-logo.png" width="80">
        <img src="@/assets/login/roc-novel.png" width="108" class="mt-4">
        <div class="mt-16 relative w-300px h-45px bg-#1A77F2 border-rd-25px text-#fff flex items-center justify-center" @click="signInWithFacebook">
          <img src="@/assets/login/icon-facebook-logo.png" width="20" class="absolute top-50% mt--10px left-30px">
          Sign in with Facebook
        </div>
        <div class="mt-4 relative w-300px h-45px bg-#239FFB border-rd-25px text-#fff flex items-center justify-center" @click="signInWithGoogle">
          <img src="@/assets/login/icon-google-logo.png" width="20" class="absolute top-50% mt--10px left-30px">
          Sign in with Google
        </div>

        <div v-if="showLogout === '1'" class="mt-4 relative w-300px h-45px bg-#239FFB border-rd-25px text-#fff flex items-center justify-center" @click="logout">
          logout
        </div>
        <div class="mt-30px text-#9875FB text-center" @click="setEmailLogin">
          Continue with Email
        </div>
      </div>

      <!-- 邮箱登录 -->
      <div v-else class="mt-180px flex flex-col items-center">
        <div class="text-20px font-bold">
          Sign in with Email
        </div>

        <div class="w-300px">
          <div class="text-#9296A0 text-12px mt-10px mb-5px">
            Your Email
          </div>
          <div class="flex items-center space-x-5 relative border border-#D0CAE4 border-1px rounded-full overflow-hidden">
            <img class="w-4 h-4 absolute left-4 top-4" src="@/assets/icon-email.png">
            <input
              v-model="form.email"
              class="!ml0 flex-1 h-11 w-full pl-10 pr-4 rounded-full border-0 !outline-0 !bg-transparent"
              placeholder=""
              maxlength="50"
            >
          </div>
        </div>

        <div class="w-300px">
          <div class="text-#9296A0 text-12px mt-10px mb-5px">
            Verification code
          </div>
          <div class="flex items-center space-x-5 relative border border-#D0CAE4 border-1px rounded-full overflow-hidden">
            <img class="w-4 h-4 absolute left-4 top-4" src="@/assets/icon-email-code.png">
            <input
              v-model="form.code"
              class="!ml0 flex-1 h-11 w-full pl-10 pr-4 rounded-full border-0 !outline-0 !bg-transparent"
              placeholder=""
              maxlength="6"
            >
            <div v-if="form.timeout > 0" class="text-#9296A0 w-40px text-align-left">
              {{ form.timeout }}s
            </div>
            <div
              v-else
              class="w-132px text-#9875FB text-12px text-align-left"
              :class="{ 'text-#9875FB': isRightEmail, 'text-#D0CAE4': !isRightEmail }"
              @click="getCode"
            >
              Get verification code
            </div>
          </div>
        </div>

        <div class="w-300px h-45px text-#fff bg-#9875FB line-height-45px text-center border-rd-23px mt-30px" @click="beforeSignIn">
          sign in
        </div>
      </div>

      <div class="fixed bottom-48px w-282px text-#9296A0 text-12px text-center">
        if you continue,you agree to the <a href="https://h5.rocnovel.com/#/readme">UserAgreement</a> and <a href="https://h5.rocnovel.com/#/secret">Privacy Policy</a>
      </div>
    </div>

    <div v-else class="h-screen">
      <div class="pt-20vh flex justify-center">
        <BasicSpinner class="mx-auto" />
      </div>
      <p class="mt-4 text-center font-bold">
        login...
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.login-bg {
  width: 100vw;
  height: 100vh;
  background-image: url('@/assets/login/login-bg.png');
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  position: relative;

  a {
    text-decoration: underline;
  }
}
</style>
