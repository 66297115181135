export function isInThirdAppBrowser() {
  const ua = navigator.userAgent.toLowerCase()
  const socialPatterns = [
    // 关键应用特征 (2025 最新验证)
    /instagram\s*[\d.]*\s+like/, // Instagram 新版特征
    /(fb(an|av)|facebook)/, // Facebook 应用内浏览器
    /(tiktok|musical\.ly)/, // TikTok 全系产品
    /line\//, // Line 内置浏览器
    /snapchat\//, // Snapchat 内置浏览器
    /twitterandroid\/[\d.]+/, // Twitter(X) 安卓客户端
    /wechat(\/[\d.]+)?\s+micromessenger/, // 微信浏览器
    /linkedinapp\/[\d.]+/, // LinkedIn 应用内浏览器
  ]

  // 特征组合验证（防止 UA 伪造）
  return socialPatterns.some(p => p.test(ua))
}

export function isAppInstalled() {
  return window.matchMedia('(display-mode: standalone)').matches || (window as any).navigator.standalone || false
}

export function isChrome() {
  const ua = navigator.userAgent
  // 精准匹配 Chrome 特征（桌面端+移动端）
  return (/chrome|chromium/i.test(ua) || /crios|crmo/i.test(ua))
         && !/edge|edg|opr|opera|android webview|fxios|firefox|iemobile|windows phone|bb10|vivaldi|yabrowser|ucbrowser/i.test(ua)
}

export function isSafari() {
  const ua = navigator.userAgent
  // 关键特征检测（排除 Chrome/Chromium 的干扰）
  return /safari/i.test(ua)
         && !/chrome|chromium|crios|crmo|android|firefox|edge|opr|opera|iemobile|windows phone|bb10|vivaldi|yabrowser|ucbrowser/i.test(ua)
}
