/**
 * 一些业务相关的 helper 函数, 所有函数都以 `$` 开头
 */

import { v4 as uuid } from 'uuid'
import queryString from 'query-string'
import { generateOAuthUrl } from './wechat'
import { PAY_LOCATION_PAGE, STORAGE_APP_ID, STORAGE_TOKEN, STORAGE_UUID, isIOS } from '@/constants'

export function $uuid() {
  let id = window.localStorage.getItem(STORAGE_UUID)
  if (id)
    return id

  id = uuid()
  window.localStorage.setItem(STORAGE_UUID, id)
  return id
}

export function $getAppId() {
  return window.localStorage.getItem(STORAGE_APP_ID)
}
export function $setAppId(appId: string) {
  window.localStorage.setItem(STORAGE_APP_ID, appId)
}
export function $removeAppId() {
  window.localStorage.removeItem(STORAGE_APP_ID)
}
export function $getToken() {
  return window.localStorage.getItem(STORAGE_TOKEN)
}
export function $setToken(token: string) {
  window.localStorage.setItem(STORAGE_TOKEN, token)
}
export function $removeToken() {
  window.localStorage.removeItem(STORAGE_TOKEN)
}

export function $getDeviceId() {
  return window.localStorage.getItem('device-uuid')
}
export function $setDeviceId(token: string) {
  window.localStorage.setItem('device-uuid', token)
}
export function $removeDeviceId() {
  window.localStorage.removeItem('device-uuid')
}

// 给txt的cdn添加一些参数, 避免一些蹊跷的问题
// 例如同一用户登录, 在app看书后, 在web端打不开章节
function updateUrl(url: string) {
  const currentTimestamp = Math.floor(Date.now() / 1000)
  const urlObj = new URL(url)
  if (urlObj.searchParams.has('t'))
    urlObj.searchParams.set('t', currentTimestamp.toString())

  else
    urlObj.searchParams.append('t', currentTimestamp.toString())

  urlObj.searchParams.set('type', 'web')
  return urlObj.toString()
}

export function $loadTextFromCDN(url: string) {
  const updatedUrl = updateUrl(url)
  return new Promise<string>((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', updatedUrl)
    xhr.onload = () => {
      if (xhr.status === 200)
        resolve(xhr.responseText)

      else reject(xhr.statusText)
    }
    xhr.onerror = () => reject(xhr.statusText)
    xhr.send()
  })
}

export function $formatErrorMessage(error: any, fallback?: string) {
  if (error.msg)
    return error.msg
  if (error instanceof Error)
    return error.message
  if (typeof error === 'string')
    return error
  if (fallback)
    return fallback
  return JSON.stringify(error)
}

export function $gotoPayProduct({
  userStore,
  configStore,
  queryStore,
  trackValueStore,
  productId,
  payLocation = PAY_LOCATION_PAGE.NONE,
  redirect = window.location.href,
  contentId = '',
  chapterId = '',
  rechargeOaaId = '',
  rechargeStrategyId = '',
}: {
  userStore: any
  configStore: any
  queryStore: any
  trackValueStore: any
  productId: string
  payLocation?: PAY_LOCATION_PAGE
  redirect?: string
  contentId?: string
  chapterId?: string
  rechargeOaaId?: string
  rechargeStrategyId?: string
}) {
  const oAuthUrl = generateOAuthUrl({
    appId: configStore.$state.payAppId,
    redirect: `${configStore.$state.payDomain}/paying?${queryString.stringify({
      redirect: window.encodeURIComponent(redirect),
      callbackUrl: window.encodeURIComponent(redirect),
      productId,
      memberId: userStore.$state.memberId,
      appId: queryStore.$state.max_app_id,
      promotionId: queryStore.$state.max_pid,
      paySid: queryStore.$state.max_sid,
      appVersion: __APP_VERSION__,
      OS: isIOS ? 2 : 1, // 1: Android, 2: iOS, 3: Windows,
      payLocation,
      contentId,
      chapterId,
      rechargeOaaId,
      rechargeStrategyId,
      ...queryStore.$state,
      ...trackValueStore.$state,
    })}`,
  })
  window.location.replace(oAuthUrl)
}

export function $getCookie(name: string) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2)
    return parts.pop()?.split(';').shift()
}

export function $getQueryParam(param: string) {
  const regex = new RegExp(`[?&]${param}=([^&#]*)`, 'i')
  const results = regex.exec(window.location.href)
  return results ? decodeURIComponent(results[1]) : null
}
