import Vue from 'vue'
import { PiniaVuePlugin, createPinia } from 'pinia'
import VueRouter from 'vue-router'
import { HeadVuePlugin, createHead } from '@vueuse/head'
import 'uno.css'
import PortalVue from 'portal-vue'
import VConsole from 'vconsole'
import Clarity from '@microsoft/clarity'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { STORAGE_DEBUG } from './constants'
import router from '@/router'
import App from '@/App.vue'
import { disableAdjustFontSize } from '@/wechat'

Vue.config.productionTip = false

// Pinia
const pinia = createPinia()
Vue.use(PiniaVuePlugin)

// Head
const head = createHead()
Vue.use(HeadVuePlugin)

// Router
Vue.use(VueRouter)

// Portal
Vue.use(PortalVue)

// Ignore custom elements
Vue.config.ignoredElements = ['wx-open-subscribe']

try {
  disableAdjustFontSize()
}
catch {}

if (!import.meta.env.MODE.includes('production') || window.localStorage.getItem(STORAGE_DEBUG)) {
  // eslint-disable-next-line no-new
  new VConsole()
}

if (import.meta.env.MODE.includes('production') || import.meta.env.MODE === 'testing') {
  Clarity.init(import.meta.env.MODE.includes('production') ? 'pu57rvx68i' : 'pwptn7ldxz')
  Sentry.init({
    Vue,
    environment: window.location.hostname,
    dsn: 'https://aeba51f61b9573c4a29dc341515ba7d9@sentry.rocnovel.com/2',
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/yourserver\.io\/api/,
        ],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracesSampleRate: 0.1,
  })
}

// 注册 serviceWorker, 为了加桌
if ('serviceWorker' in navigator)
  navigator.serviceWorker.register('/serviceWorker.js').then(() => {})

new Vue({
  router,
  pinia,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  head,
  render: h => h(App),
}).$mount('#app')
