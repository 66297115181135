import { ref } from 'vue'
import { api } from '@/api'
import { $formatErrorMessage } from '@/helpers'
import { useLoadingStore, useToastStore, useUserStore } from '@/stores'
import { track } from '@/track'

interface Book {
  contentId: string
  contentChapterId: string
  bookName: string
  chapterName: string
  serialStatus: number
}

export function usePurchase(book?: Book) {
  const templateProducts = ref<any>([])
  const data = ref<any>({})
  const PayTemplateSuccess = ref(false)

  const { $toast } = useToastStore()
  const { $showLoading, $hideLoading } = useLoadingStore()
  const userStore = useUserStore()

  const getTopUpList = async function () {
    try {
      const res: any = await api.get<any>('/member/topup/product/list', {
        params: {
          type: book?.contentId ? 1 : 2,
          contentId: book?.contentId || '',
          contentChapterId: book?.contentChapterId || '',
        },
      })
      data.value = res.data || {}
      templateProducts.value = res.data || []
    }
    catch (error) {
      $toast($formatErrorMessage(error))
    }
  }

  const trackPurchase = function () {
    const common = {
      is_first_purchase: (userStore.$state.rechargeCount || 0) > 0 ? 0 : 1,
      purchase_id: templateProducts.value[0]?.payConfigId || '',
      purchase_name: templateProducts.value[0]?.payConfigName || '',
      currency_coins: userStore.$state.coinBalance,
      layer_id: data.value.memberLayerId || '',
    }

    if (book) {
      track('purchasePopShow', {
        book_id: book.contentId,
        chapter_id: book.contentChapterId,
        book_name: book.bookName,
        chapter_name: book.chapterName,
        serial_status: book.serialStatus === 1 ? 'completed' : 'ongoing',
        ...common,
      })
      return
    }

    return track('purchaseCenterShow', common)
  }

  const PayTemplate = async function (item: any) {
    $showLoading()
    const url = `${window.location.origin}/paying?redirect_source=${encodeURIComponent(window.location.href)}`
    const params = {
      payConfigId: item.payConfigId,
      productId: item.productId,
      contentId: book?.contentId,
      contentChapterId: book?.contentChapterId,
      memberCurrencyCode: 'usd',
      payType: 6,
      type: 2,
      successUrl: `${url}&payment_status=success`,
      cancelUrl: `${url}&payment_status=cancel`,
    }
    try {
      const { data } = await api.post<any>('/member/topup/createPaypalPaymentIntent', params)
      PayTemplateSuccess.value = true
      window.location.href = data
    }
    catch (error) {
      $toast($formatErrorMessage(error))
    }
    $hideLoading()
  }

  getTopUpList()
  trackPurchase()

  return {
    templateProducts,
    data,
    PayTemplateSuccess,
    PayTemplate,
  }
}
