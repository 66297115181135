import { computed, reactive } from 'vue'
import { useRouter } from 'vue-router/composables'
import { useLoadingStore, useToastStore } from '@/stores'
import { $setToken } from '@/helpers'
import { api } from '@/api'

export function useEmailLogin(deviceUuid: string, loginToken: string, redirect: string) {
  const { $toast } = useToastStore()
  const { $showLoading, $hideLoading } = useLoadingStore()
  const router = useRouter()

  let timer: any = null

  const form = reactive({
    email: '',
    code: undefined as string | undefined,
    timeout: 0,
  })
  const isRightEmail = computed(() => {
    const reg = /^([A-Za-z0-9_\-\.\u4E00-\u9FA5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/
    return reg.test(form.email)
  })

  function countdown() {
    form.timeout = 60
    timer = setInterval(() => {
      form.timeout--
      if (form.timeout <= 0)
        clearInterval(timer)
    }, 1000)
  }

  function resetEmail() {
    form.timeout = 0
    form.code = undefined
    form.email = ''
    timer && clearInterval(timer)
  }

  async function getCode() {
    if (!form.email) {
      $toast('Please enter your email')
      return
    }

    if (!isRightEmail.value) {
      $toast('Invalid email format')
      return
    }

    try {
      $showLoading()
      const res = await api.post<PostMemberEmailBody, PostMemberEmailResponse>('/member/email', {
        toAddress: form.email,
      })

      if (res.code === 0)
        countdown()

      $hideLoading()
    }
    catch (e: any) {
      $hideLoading()
      $toast(e.msg)
    }
  }

  function beforeSignIn() {
    if (!form.email) {
      $toast('Please enter your email')
      return
    }

    if (!isRightEmail.value) {
      $toast('Invalid email format')
      return
    }

    if (!form.code) {
      $toast('Please enter the verification code')
      return
    }

    login()
  }

  async function login() {
    try {
      $showLoading()
      const res = await api.post<any, PostMemberBindThirdPartResponse, PostMemberBindThirdPartBody>('/member/bind/thirdPart', {
        verifyCode: form.code,
        thirdPartId: form.email,
        thirdType: 7,
      }, {
        headers: {
          equipmentId: deviceUuid,
          token: loginToken,
        },
      })

      if (res.code === 0) {
        if (redirect) {
          const url = new URL(redirect)
          url.searchParams.set('t', res.data.token)
          window.location.replace(url.toString())
        }
        else {
          $setToken(res.data.token)
          router.replace({ name: 'member' })
        }
      }

      $hideLoading()
    }
    catch (e: any) {
      console.error('e', e)
      $hideLoading()
      $toast(e.msg)
    }
  }

  return {
    form,
    isRightEmail,
    getCode,
    beforeSignIn,
    resetEmail,
  }
}
