<script lang="ts" setup>
import { useUserStore } from '@/stores'
import { usePurchase } from '@/composables/usePurchase'

const user = useUserStore()
const { templateProducts, PayTemplate } = usePurchase()
</script>

<template>
  <div>
    <BasicNavbar />

    <!-- Cover -->
    <div class="pay-cover mt-44px mb-24px mx-10px bg-#fff b-rd-2 box-shadow">
      <div class="pt-18px px-26px">
        <p class="flex items-center space-x-5px">
          <span class="text-18px text-#9875FB font-bold">{{ user.$state.coinBalance }}</span>
          <span class="relative text-12px text-#666">coins</span>
        </p>
      </div>
      <div class="pay-template pt-2px pb-12px">
        <!-- 充值 -->
        <div v-for="(item) in templateProducts" :key="item.productId" class="pay-template-info flex items-center space-x-15px mt-10px mx-10px px-16px py-16px b-rd-2 bg-#F3F5FA border border-color-#F3F5FA" @click="PayTemplate(item)">
          <div>
            <img class="w-24px h-24px" src="@/assets/welfare/welfare-gold.png">
          </div>
          <div class="flex-1">
            <div>
              <strong class="text-18px">{{ item.coinsBuy }}</strong>
              <span class="ml-5px text-14px text-#65686F">coins</span>
            </div>
            <div class="flex items-center">
              <span class="text-14px text-#9875FB">+{{ item.coinsBonus }} coins</span>
              <i v-if="item.marketingText" class="inline-block ml-10px px-9px bg-#F74984 b-rd-12px text-#fff text-10px font-not-italic">{{ item.marketingText }}</i>
            </div>
          </div>
          <div class="text-right">
            <strong class="text-18px">$ {{ item.price }}</strong>
            <p v-if="item.cornerTip" class="text-12px text-#65686F">
              +{{ parseFloat((item.coinsBonus / item.coinsBuy * 100).toFixed(2)) }}% coins
            </p>
          </div>
        </div>
      </div>
      <div class="pb-20px px-16px text-10px text-#9296A0">
        <p class="text-12px mt-6px">
          Tips:
        </p>
        <p class="mt-6px">
          1. Coins are virtual items and cannot be refunded. They can only be used within this app.
        </p>
        <p class="mt-6px">
          2. Reward coins are time-sensitive. Please use them within the 7-day validity period, as they will be deducted first when unlocking content. Coins are not redeemable or transferable to other users.
        </p>
        <p class="mt-6px">
          3. For other questions, please contact us via Profile > Customer Service.
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pay-cover {
  min-height: calc(100vh - 68px);
}
</style>
