import { ref } from 'vue'
import { api } from '@/api'
import { useLoadingStore, useToastStore } from '@/stores'
import { $formatErrorMessage } from '@/helpers'
import { SubscriptionCycle } from '@/utils/enum'

interface Book {
  contentId: string
  contentChapterId: string
}

export function useSubscript(book?: Book) {
  const subscribeProducts = ref<MemberTopupSubscribeProduct[]>([])
  const subscribeSuccess = ref(false)
  const loaded = ref(false)
  let successUrl = window.location.href

  // 充值中心
  if (!book?.contentId)
    successUrl = `${window.location.origin}/member`

  const { $toast } = useToastStore()
  const { $showLoading, $hideLoading } = useLoadingStore()

  const getSubscriptList = async function () {
    try {
      const res: any = await api.get('/member/topup/subscribe/product/List', {})
      subscribeProducts.value = res.data || []
    }
    catch (error) {
      $toast($formatErrorMessage(error))
    }
    loaded.value = true
  }

  const getUnitPrice = function (item: MemberTopupSubscribeProduct) {
    if (!item.cycle)
      return ''

    if (item.cycle === SubscriptionCycle.YEAR)
      return `${(Number(item.price) / 12).toFixed(2)}/month`

    if (item.cycle === SubscriptionCycle.HALF_YEAR)
      return `${(Number(item.price) / 6).toFixed(2)}/month`

    if (item.cycle === SubscriptionCycle.SEASON)
      return `${(Number(item.price) / 3).toFixed(2)}/month`

    if (item.cycle === SubscriptionCycle.MONTH)
      return `${(Number(item.price) / 30).toFixed(2)}/day`

    if (item.cycle === SubscriptionCycle.WEEK)
      return `${(Number(item.price) / 7).toFixed(2)}/day`

    if (item.cycle === SubscriptionCycle.ThreeDays)
      return `${(Number(item.price) / 3).toFixed(2)}/day`

    return ''
  }

  const onSubscribeProducts = async function (item: any) {
    $showLoading()
    const url = `${window.location.origin}/paying?redirect_source=${encodeURIComponent(successUrl)}`
    const params = {
      payConfigId: item.subscribeConfigId,
      productId: item.productId,
      memberCurrencyCode: 'usd',
      subscribeConfigId: item.subscribeConfigId,
      contentId: book?.contentId,
      contentChapterId: book?.contentChapterId,
      payType: 6,
      type: 2,
      successUrl: `${url}&payment_status=success&type=subscribe&subscribeType=${item.subscribeType}&discountOnceCoins=${item.discountOnceCoins}`,
      cancelUrl: `${url}&payment_status=cancel&type=subscribe&subscribeType=${item.subscribeType}`,
    }

    try {
      const { data } = await api.post<PostMemberSubscribeCreateOrderQuery, PostMemberSubscribeCreateOrderResponse>('/member/subscribe/createOrder', params)
      subscribeSuccess.value = true
      window.location.href = data
    }
    catch (error) {
      $toast($formatErrorMessage(error))
    }
    $hideLoading()
  }

  getSubscriptList()

  return {
    loaded,
    subscribeProducts,
    subscribeSuccess,
    onSubscribeProducts,
    getUnitPrice,
  }
}
