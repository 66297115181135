<script lang="ts" setup>
import { track } from '@/track'

const props = defineProps<{
  name: string
  extra?: Record<string, any>
}>()

function onClick() {
  track('buttonClick', {
    button_name: props.name,
    ...props.extra,
  })
}
</script>

<template>
  <div @click.capture="onClick">
    <slot />
  </div>
</template>
