<script lang="ts" setup>
import { useCallRocnovelApp } from '@/composables/useBannerShow'

const props = defineProps<{
  position: 'fixed' | 'block'
  id?: string
  index?: number
}>()
const { callApp } = useCallRocnovelApp()
</script>

<template>
  <div
    class="
      download-app-banner
      h-60px
      flex
      items-center
      justify-between
      px-4
    "
    :class="{
      'my-10px': props.position === 'block',
      'my-0px': props.position === 'fixed',
      'border-rd-8px': props.position === 'block',
      'border-rd-0px': props.position === 'fixed',
    }"
  >
    <div class="text-#7250D0 font-medium">
      Get More Daily updated Books
    </div>

    <div class="w-110px h-28px border-rd-16px text-12px text-#fff  bg-#9875FB line-height-28px text-center" @click="callApp(id, index)">
      Download Now
    </div>
  </div>
</template>

<style lang="scss" scoped>
.download-app-banner {
  background-image: url('@/assets/banner.png');
  background-size: 100% 100%;
}
</style>
