/**
 * track名: content_chapter_read_complete
 * 内容章节完读事件
 * @param {Number} content_id 内容ID
 * @param {Number} chapter_id 章节ID
 * @param {Number} read_cost_second 停留时长
 * @param {Number} chapter_no 章节序号
*/

import moment from 'moment'
import { ref } from 'vue'
import { newTrack } from '@/track'

export function useChapterReadComplete() {
  const during = 10
  const isReported = ref(false)
  const currentTime = ref(moment())

  function resetReadComplete() {
    isReported.value = false
    currentTime.value = moment()
  }

  function readCompleteTrack(contentId: number | string, chapterId: number | string, chapterNo: number) {
    if (isReported.value)
      return

    const isAfter = moment().diff(currentTime.value, 'seconds') > during
    if (!isAfter) {
      isReported.value = true
      return
    }

    newTrack('content_chapter_read_complete', {
      content_id: contentId,
      chapter_id: chapterId,
      chapter_no: chapterNo,
      read_cost_second: moment().diff(moment(currentTime.value), 'seconds'),
    })

    isReported.value = true
  }

  function chapterVisitTrack(contentId: number | string, chapterId: number | string, chapterNo: number) {
    newTrack('content_chapter_visit', {
      content_id: contentId,
      chapter_id: chapterId,
      chapter_no: chapterNo,
    })
  }

  return {
    chapterVisitTrack,
    readCompleteTrack,
    resetReadComplete,
  }
}
