import queryString from 'query-string'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { set } from 'vue'
import { v4 as uuid } from 'uuid'
import { getDeviceFeature } from './utils/deviceFeature'
import { normalizeHttps, sleep } from '@/utils'
import { $getToken, $removeDeviceId, $removeToken, $setDeviceId, $setToken } from '@/helpers'
import { api } from '@/api'
import { STORAGE_READ_CHAPTER_LAST, STORAGE_TRACK_VALUE_CHANNEL_NAME_MAP, STORAGE_TRACK_VALUE_FIRST_ORIGIN_MAP, STORAGE_TRACK_VALUE_ORIGIN_NAME_MAP, isIOS } from '@/constants'
import { initTrack } from '@/track'

export const useEffectiveSubmitTrackStore = defineStore({
  id: 'effectiveSubmitTrack',
  state: () => ({
    isAttribution: false,
    params: {},
  }),
  actions: {
    $attribution() {
      this.$state.isAttribution = true
    },

    $unAttribution() {
      this.$state.isAttribution = false
    },

    $setParams(params: Record<string, any>) {
      this.$state.params = params
    },
  },
})

export const useLoadingStore = defineStore({
  id: 'loading',
  state: () => ({
    visible: false,
  }),
  actions: {
    $showLoading() {
      this.$state.visible = true
    },
    $hideLoading() {
      this.$state.visible = false
    },
  },
})

export const useToastStore = defineStore({
  id: 'toast',
  state: () => ({
    message: '',
  }),
  actions: {
    async $toast(message: string, duration = 1500) {
      this.message = message
      await sleep(duration)
      this.message = ''
    },
  },
})

export const useHistoryStore = defineStore({
  id: 'history',
  state: () => ({
    stack: [] as string[],
  }),
  actions: {
    $push(url: string) {
      this.stack.push(url)
    },
    $replace(url: string) {
      set(this.$state.stack, Math.max(this.stack.length - 1, 0), url)
    },
    $pop() {
      const url = this.stack[this.stack.length - 2]
      set(this.$state, 'stack', this.stack.slice(0, -2))
      return url
    },
    $clear() {
      this.stack = []
    },
  },
})

// Common query
export const useQueryStore = defineStore({
  id: 'query',
  state: () => ({
    max_pid: '',
    max_sid: '',
    max_app_id: '',
  }),
  actions: {
    init(query: Record<string, any>) {
      if (query.max_pid)
        this.max_pid = query.max_pid as string
      if (query.max_sid)
        this.max_sid = query.max_sid as string
      if (query.max_app_id)
        this.max_app_id = query.max_app_id as string
    },
    stringify() {
      return queryString.stringify({
        max_app_id: this.$state.max_app_id,
        max_pid: this.$state.max_pid,
        max_sid: this.$state.max_sid,
      })
    },
  },
})

interface ReadChapterLastData {
  contentId?: string
  chapterId?: string
  expire?: number
}

const duration = 1000 * 60 * 5 // 5min
export const useReadStore = defineStore({
  id: 'read',
  state: () => {
    let readChapterLast = {} as ReadChapterLastData
    try {
      readChapterLast = JSON.parse(localStorage.getItem(STORAGE_READ_CHAPTER_LAST) ?? '{}') as ReadChapterLastData
      if (readChapterLast.expire && readChapterLast.expire < Date.now())
        readChapterLast = {}
    }
    catch {}
    return {
      contentId: readChapterLast?.contentId ?? '',
      chapterId: readChapterLast?.chapterId ?? '',
    }
  },
  actions: {
    update({ contentId, chapterId }: { contentId: string; chapterId: string }) {
      if (!contentId || !chapterId)
        return
      this.contentId = contentId
      this.chapterId = chapterId
      localStorage.setItem(STORAGE_READ_CHAPTER_LAST, JSON.stringify({
        contentId,
        chapterId,
        expire: Date.now() + duration,
      }))
      setTimeout(() => {
        this.$reset()
      }, duration)
    },
  },
})

export const useConfigStore = defineStore({
  id: 'config',
  state: () => ({
    _fetched: false,
    appId: '',
    appName: '',
    appPackageId: '',
    appType: '',
    componentAppId: '',
    oauthDomain: '',
    payDomain: '',
    payAppId: '',
    forceAttentionMp: false,
    noPopup: false,
    mpAppId: '',
    wxAppName: '',
    subscribeTemplateList: [] as { templateId: string }[],
    strategy: null as any,
    alreadyGetStratrgy: false,
    // 自动续费 & 破冰
    renewVipState: false,
    renewIceBreakingState: false,
    renewIceBreakingStyleConfig: '1' as string | number,
  }),
  actions: {
    async init(options: { force: boolean } = { force: false }) {
      if (this.$state._fetched && !options.force)
        return
      const { data } = await api.get<null, GetAppConfigGetResponse>('/app/config/get')
      this.$state = { ...this.$state, ...data, _fetched: true } as any

      window.collectCommonParams = window.collectCommonParams ?? {}
      window.collectCommonParams = {
        ...window.collectCommonParams,
        os: isIOS ? 'ios' : 'andr',
        pline: '微信公众号',
        content_type: '长篇',
        wx_app_name: data.appName,
        wx_appid: data.mpAppId,
        partner_id: data.partnerId,
      }

      // Normalize some domains
      this.$state.oauthDomain = normalizeHttps(this.$state.oauthDomain)

      // Note: Update common query
      const queryStore = useQueryStore()
      queryStore.$patch({
        max_pid: data.pid,
        max_sid: data.sid,
        max_app_id: data.appId,
      })

      // Update document title
      if (this.$state.wxAppName)
        document.title = this.$state.wxAppName
    },
    updateStrategy(value: any) {
      this.$state.strategy = value
    },
    updateAlreadyGetStratrgy(state: boolean) {
      this.$state.alreadyGetStratrgy = !!state
    },
  },
})

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    _fetched: false,
    appId: '',
    coinBalance: 0,
    code: '',
    memberId: '',
    memberName: '',
    headImageUrl: '',
    thirdPartId: '',
    thirdNickName: '',
    thirdHeadImgUrl: '',
    thirdPartType: '',
    vipEndTime: '',
    createDateTime: '',
    email: '',
    userSubscribeState: false,
    autoLock: false,
    subscribe: false,
    subscribeRenewal: false,
    subscribeEndDate: '',
    // 迁移小程序公告
    transfer: false,
    transferLink: '',
    rechargeCount: null, // 累计充值次数
    landingPageId: '', // 从广告来的用户
    landingPageContentId: '', // 投放的内容id
    subscribeType: 0, // 订阅类型
  }),
  actions: {
    isLogin() {
      return Boolean($getToken())
    },
    async login(deviceUuid: string, isNewUser = false) {
      const effectiveSubmitTrackStore = useEffectiveSubmitTrackStore()
      const deviceFeature = await getDeviceFeature()
      await api.post<null>('/member/login', {
        equipmentId: deviceUuid,
        attributionRequest: effectiveSubmitTrackStore.$state.params,
        deviceFeature,
      }).then(async (res: any) => {
        // eslint-disable-next-line no-console
        console.log(res)
        $setToken(res?.data?.token)
        await this.init({ force: true, isNewUser })
      })
    },
    async transferLogin(transferToken: string) {
      const diviceId = uuid()
      $setDeviceId(diviceId)

      await api.post<null>('/member/login', {
        transferToken,
        equipmentId: diviceId,
      }).then(async (res: any) => {
        // eslint-disable-next-line no-console
        console.log(res)
        $setToken(res?.data?.token)
        await this.init({ force: true })
      })
    },
    logout() {
      $removeToken()
      $removeDeviceId()
    },
    async init(options: { force: boolean; isNewUser?: boolean } = { force: false, isNewUser: false }) {
      if (this.$state._fetched && !options.force)
        return
      const [{ data }] = await Promise.all([
        api.get<null, GetMemberDetailGetResponse>('/member/detail/get').then((res) => {
          return res
        }),
      ])

      // 在 app 端被删除, 重新生成用户
      if (data.signOut) {
        await this.logout()
        const diviceId = uuid()
        $setDeviceId(diviceId)
        await this.login(diviceId, true)
      }

      this.$state = { ...this.$state, ...data, _fetched: true }
      if (window.clarity)
        window.clarity('identify', String(data.code))

      initTrack()
    },
  },
})

export const useEmailLogin = defineStore({
  id: 'emailLogin',
  state: () => ({
    visible: false,
  }),
  actions: {
    $showLoginPopup() {
      this.$state.visible = true
    },
    $hideLoginPopup() {
      this.$state.visible = false
    },
    isEmailLogin() {
      const user = useUserStore()
      return Boolean(user.email)
    },
  },
})

export const useActivityStore = defineStore({
  id: 'activity',
  state: () => ({
    _fetched: false,
  } as { _fetched: boolean } & {
    [key in string]: {
      id: string
      name: string
      title: string
      startDate: string
      endDate: string
      activityTemplateId: string
      activityType: number
      productId: string
      limitTimes: number
      limitType: number
      toastPosition: number
      toastPositionList: null
      toastStat: number
    }
  }),
  actions: {
    async init() {
      if (this.$state._fetched)
        return
      try {
        const { data } = await api.get<null, GetActivityGettoastactivitymapResponse>('/activity/getToastActivityMap')
        this.$state = { ...this.$state, ...data, _fetched: true } as any
      }
      catch {} // ignore error
    },
  },
})

export const useRechargeInterceptionStore = defineStore({
  id: 'rechargeInterception',
  state: () => ({
    _exist: false,
    rechargeBlockingActivityId: '',
    rechargeBlockingActivityName: '',
    rechargeBlockingActivityImgUrl: '',
    productId: '',
    productName: '',
    productDescription: '',
    productType: 1,
    prizeId: '',
    prizeName: '',
    cname: '',
    minRechargeAmount: 0,
    redPacketAmount: 0,
    countDown: 0,
    pointerState: 0,
    interceptType: 1, // 减金额 = 1,固定金额 = 2
    style: 1,
    coinsBuy: 0,
    coinsBonus: 0,
    cornerTip: '',
    footTip: '',
    exchangeAmount: 1,
  }),
  actions: {
    async init() {
      const { data } = await api.get<null, any>('/activity/getRechargeBlockingActivityInfo')
      const _exist = Boolean(data)
      this.$state = { ...this.$state, ...data, _exist }
    },
  },
})

// refs: https://doc.weixin.qq.com/sheet/e3_AbAA8Ab1AA0MTQcebGWTIyH9wx7pC?scode=AAsAkAdiAAs002NeV3AbAA8Ab1AA0&tab=lmg39t
const week = 7 * 24 * 60 * 60 * 1000
const month = 30 * 24 * 60 * 60 * 1000
const quarter = 3 * month
export const useTrackValueStore = defineStore({
  id: 'trackValue',
  state: () => {
    const bookId = '0'
    const firstOriginData = getLocalStorageWithUnexpired(STORAGE_TRACK_VALUE_FIRST_ORIGIN_MAP)
    const originNameData = getLocalStorageWithUnexpired(STORAGE_TRACK_VALUE_ORIGIN_NAME_MAP)
    const chlNameData = getLocalStorageWithUnexpired(STORAGE_TRACK_VALUE_CHANNEL_NAME_MAP)
    return {
      bookId,
      firstOrigin: firstOriginData?.[bookId]?.firstOrigin ?? 0,
      originName: originNameData?.[bookId]?.originName ?? 0,
      chlName: chlNameData?.[bookId]?.chlName ?? 0,
    }
  },
  actions: {
    set({ bookId = '0', firstOrigin = 0, originName = 0, chlName = 0 }: { bookId?: string; firstOrigin?: number; originName?: number; chlName?: number }) {
      if (bookId === '0') {
        if (firstOrigin && this.firstOrigin === 0)
          this.firstOrigin = firstOrigin

        if (originName && this.originName === 0)
          this.originName = originName

        if (chlName && this.chlName === 0)
          this.chlName = chlName
      }
      else {
        this.bookId = bookId
        const firstOriginData = getLocalStorageWithUnexpired(STORAGE_TRACK_VALUE_FIRST_ORIGIN_MAP)
        const originNameData = getLocalStorageWithUnexpired(STORAGE_TRACK_VALUE_ORIGIN_NAME_MAP)
        const chlNameData = getLocalStorageWithUnexpired(STORAGE_TRACK_VALUE_CHANNEL_NAME_MAP)

        if (firstOrigin && !firstOriginData?.[bookId]?.firstOrigin) {
          this.firstOrigin = firstOrigin
          firstOriginData[bookId] = { firstOrigin, expire: Date.now() + quarter }
          localStorage.setItem(STORAGE_TRACK_VALUE_FIRST_ORIGIN_MAP, JSON.stringify(firstOriginData))
        }
        if (originName && !originNameData?.[bookId]?.originName) {
          this.originName = originName
          originNameData[bookId] = { originName, expire: Date.now() + week }
          localStorage.setItem(STORAGE_TRACK_VALUE_ORIGIN_NAME_MAP, JSON.stringify(originNameData))
        }
        if (chlName && !chlNameData?.[bookId]?.chlName) {
          this.chlName = chlName
          chlNameData[bookId] = { chlName, expire: Date.now() + week }
          localStorage.setItem(STORAGE_TRACK_VALUE_CHANNEL_NAME_MAP, JSON.stringify(chlNameData))
        }
      }
    },
    setFromLocal(bookId: string) {
      const firstOriginData = getLocalStorageWithUnexpired(STORAGE_TRACK_VALUE_FIRST_ORIGIN_MAP)
      const originNameData = getLocalStorageWithUnexpired(STORAGE_TRACK_VALUE_ORIGIN_NAME_MAP)
      const chlNameData = getLocalStorageWithUnexpired(STORAGE_TRACK_VALUE_CHANNEL_NAME_MAP)

      if (!this.firstOrigin)
        this.firstOrigin = firstOriginData?.[bookId]?.firstOrigin ?? 0
      if (!this.originName)
        this.originName = originNameData?.[bookId]?.originName ?? 0
      if (!this.chlName)
        this.chlName = chlNameData?.[bookId]?.chlName ?? 0
    },
    // 上报之前清洗下数据
    refresh() {
      const firstOriginData = getLocalStorageWithUnexpired(STORAGE_TRACK_VALUE_FIRST_ORIGIN_MAP)
      const originNameData = getLocalStorageWithUnexpired(STORAGE_TRACK_VALUE_ORIGIN_NAME_MAP)
      const chlNameData = getLocalStorageWithUnexpired(STORAGE_TRACK_VALUE_CHANNEL_NAME_MAP)

      if (!this.firstOrigin) {
        this.firstOrigin = firstOriginData?.['0']?.firstOrigin
        delete firstOriginData?.['0']
      }
      if (!this.originName) {
        this.originName = originNameData?.['0']?.originName
        delete originNameData?.['0']
      }
      if (!this.chlName) {
        this.chlName = chlNameData?.['0']?.chlName
        delete chlNameData?.['0']
      }

      this.set({ bookId: this.bookId, firstOrigin: this.firstOrigin, originName: this.originName, chlName: this.chlName })
      this.bookId = '0'
    },
  },
})

// JSON stringify data with `expire` field
function getLocalStorageWithUnexpired(key: string) {
  let value: undefined | { [key: string]: any }
  try {
    const data = JSON.parse(localStorage.getItem(key) ?? '{}')
    for (const bookId of Object.keys(data)) {
      const item = data[bookId]
      if (item.expire && item.expire < Date.now())
        delete data[bookId]
    }
    return data
  }
  catch {}
  return value
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useConfigStore, import.meta.hot))
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
  import.meta.hot.accept(acceptHMRUpdate(useActivityStore, import.meta.hot))
}
