<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { ERROR_PAGE_LEVEL } from '@/constants'
import { windowClose } from '@/wechat'

const props = withDefaults(
  defineProps<{
    level: ERROR_PAGE_LEVEL
  }>()
  , {
    level: ERROR_PAGE_LEVEL.DEFAULT,
  },
)

const route = useRoute()
const message = ref('')
onMounted(() => {
  try {
    message.value = window.decodeURIComponent(route.query.message as string) || '服务器错误'
  }
  catch {}
})

const router = useRouter()
function onRetry() {
  window.location.replace(window.decodeURIComponent(route.query.redirect as string ?? '/'))
}

function onHome() {
  router.push({
    name: 'home',
  })
}
function onClose() {
  windowClose()
}
</script>

<template>
  <div>
    <BasicNavbar v-if="level === ERROR_PAGE_LEVEL.DEFAULT" />
    <div class="py-4 px-8 flex flex-col items-center justify-center h-screen bg-white">
      <img class="w-100px" src="@/assets/cover-error.png">

      <template v-if="level === ERROR_PAGE_LEVEL.DEFAULT">
        <p class="mt-2 text-12px text-#9296A0 text-center">
          {{ message }}
        </p>

        <div class="flex">
          <button
            class="mt-4 px-6 py-1 text-primary border-primary border-1 border-solid rounded-full"
            @click="onRetry"
          >
            Retry
          </button>

          <button
            class="mt-4 ml-2 px-6 py-1 text-primary border-primary border-1 border-solid rounded-full"
            @click="onHome"
          >
            Home
          </button>
        </div>
      </template>
      <template v-else-if="level === ERROR_PAGE_LEVEL.FATAL">
        <p class="mt-4 w-full text-#666 text-center">
          {{ message }}
        </p>
      </template>
    </div>
  </div>
</template>
