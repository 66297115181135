<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { api } from '@/api'
import { useLoadingStore, useToastStore, useUserStore } from '@/stores'

// import { isAppInstalled, isChrome, isInThirdAppBrowser, isSafari } from '@/utils/bowserEnv'
// import { TASK_TYPE } from '@/utils/enum'
// import { tripartiteLoginPage } from '@/composables/useTripartiteLogin'

const { $toast } = useToastStore()
const { $showLoading, $hideLoading } = useLoadingStore()
const userStore = useUserStore()

const signed = ref(false)
const progress = ref<any>([])
const modalSigned = ref(false)
const progressInfo = ref<any>()
const tomorrowInfo = ref<any>()
const checkInDayNum = ref(0)

async function fetchProgress() {
  try {
    const { data } = await api.get<any>('/member/task/signTaskInfo')
    signed.value = data.isSign
    progress.value = data.memberSignResponseList

    // 当前签到信息
    if (signed.value) {
      for (let i = progress.value.length - 1; i >= 0; i--) {
        if (progress.value[i].isSign) {
          progressInfo.value = progress.value[i]
          tomorrowInfo.value = progress.value[i + 1] ?? null
          break
        }
      }
    }
    else {
      for (let i = 0; i < progress.value.length; i++) {
        if (!progress.value[i].isSign) {
          progressInfo.value = progress.value[i]
          tomorrowInfo.value = progress.value[i + 1] ?? null
          break
        }
      }
    }

    // 领取天数
    for (let i = progress.value.length - 1; i >= 0; i--) {
      if (progress.value[i].isSign) {
        checkInDayNum.value = i + 1
        break
      }
    }
    await userStore.init({ force: true })
  }
  catch (error: any) {
    console.error(error)
    $toast(error.msg || 'get check in progress failed')
  }
}

async function onCheckIn() {
  if (signed.value)
    return
  try {
    $showLoading()
    await api.get<any>('/member/task/sign')
    modalSigned.value = true
    fetchProgress()
  }
  catch (error: any) {
    console.error(error)
    $toast(error.msg || 'check in failed')
  }
  finally {
    $hideLoading()
  }
}

// #region tasks
// type ITask = TTaskList & {
//   type: string
//   status: string
// }
// const tasks = ref<ITask[]>([])
// const showGuidePopup = ref(false)

// const BasicGuidePopupAddHomeType = ref<'open' | 'addHome'>('open')
// const BasicGuidePopupAddHomeArrow = ref<'up' | 'down'>('up')
// const finishPopup = ref(false)
// const coins = ref(0)

// async function fatchTasks() {
//   try {
//     const { data } = await api.post<null, PostTaskListResponse, PostTaskListBody>('/task/list', {
//       isBind: !!userStore.$state.thirdPartType,
//       isAdd: isAppInstalled(),
//       isOpened: !isInThirdAppBrowser(),
//     })

//     tasks.value = (data || []).filter(item => (
//       item.taskType === 0
//       || item.taskType === 1
//       || (item.taskType === 2 && (isChrome() || isSafari()))
//     )) as ITask[]
//   }
//   catch (error: any) {
//     console.error(error)
//     $toast(error.msg)
//   }
// }

// const getBtnStatus = function (item: ITask) {
//   if (item.taskType === TASK_TYPE.BIND || item.taskType === TASK_TYPE.ADD_BROWSER) {
//     if (item.buttonState === 2)
//       return 'ok'
//     else
//       return 'go'
//   }

//   if (item.taskType === TASK_TYPE.ADD_TO_HOME_SCREEN) {
//     if (isAppInstalled())
//       return 'ok'
//     else
//       return 'go'
//   }
//   return ''
// }

// async function getCoins(item: ITask) {
//   try {
//     const { code } = await api.post<null, PostTaskCoinsResponse, PostTaskCoinsBody>('/task/coins', {
//       taskConfigDetailId: item.id,
//       taskType: item.taskType,
//       coins: item.coins,
//     })
//     if (code === 0) {
//       finishPopup.value = true
//       coins.value = item.coins
//       fatchTasks()
//       userStore.init({ force: true })
//     }
//   }
//   catch (error: any) {
//     console.error(error)
//     $toast(error.msg)
//   }
// }
// function handleTask(item: ITask) {
//   // 领取
//   if (item.buttonState === 2 || (item.taskType === TASK_TYPE.ADD_TO_HOME_SCREEN && isAppInstalled())) {
//     getCoins(item)
//     return
//   }

//   if (item.taskType === TASK_TYPE.BIND) {
//     tripartiteLoginPage()
//     return
//   }

//   if (item.taskType === TASK_TYPE.ADD_TO_HOME_SCREEN) {
//     BasicGuidePopupAddHomeType.value = 'addHome'
//     BasicGuidePopupAddHomeArrow.value = isSafari() ? 'down' : 'up'
//   }

//   if (item.taskType === TASK_TYPE.ADD_BROWSER) {
//     BasicGuidePopupAddHomeType.value = 'open'
//     BasicGuidePopupAddHomeArrow.value = 'up'
//   }

//   showGuidePopup.value = true
// }
// #endregion

onMounted(async () => {
  fetchProgress()
  // fatchTasks()
})
</script>

<template>
  <div class="min-h-screen pb-10">
    <!-- Cover -->
    <div class="cover w-full h-186px relative">
      <div class="flex justify-between items-center px-5 pt-3.5 pb-3">
        <div>
          <h1 class="text-12px text-#65686F">
            My Wallet
          </h1>
        </div>
        <div class="flex items-center">
          <img class="w-24px h-24px" src="@/assets/welfare/welfare-gold.png">
          <strong class="ml-5px text-16px">{{ userStore.$state.coinBalance }}</strong>
          <span class="ml-5px text-12px text-#65686F">coins</span>
        </div>
      </div>
      <div class="mx-10px px-10px py-16px bg-#fff b-rd-2">
        <h3 class="text-18px text-#323435 font-bold">
          Daily Check-in
        </h3>
        <p class="text-12px text-#9296A0">
          You have checked in for {{ checkInDayNum }} day straight!
        </p>
        <div class="mt-15px px-4px grid grid-cols-7 space-x-8px">
          <div
            v-for="(item, index) in progress"
            :key="item.num"
            class="text-center"
            :class="{ 'opacity-70': item.isSign }"
          >
            <div class="flex flex-col items-center pt-10px pb-6px bg-#F6F4FB b-rd-5px">
              <img v-if="index !== progress.length - 1" class="w-24px h-24px" src="@/assets/welfare/welfare-gold.png">
              <img v-else class="w-28px h-25px mb--1px" src="@/assets/welfare/welfare-box.png">
              <span class="mt-3px text-10px text-#32353A">+{{ item.prizeNum }}</span>
            </div>
            <div class="flex justify-center">
              <i-assets-icon-right v-if="item.isSign" class="mt-10px" />
              <span v-else class="text-10px text-#9296A0 mt-8px">Day {{ item.num }}</span>
            </div>
          </div>
        </div>
        <div class="flex justify-center">
          <a
            v-if="progress.length"
            class="block mt-4 w-200px h-9 lh-9 text-15px text-white text-center bg-primary rounded-full"
            :class="{ 'opacity-70': signed }"
            @click="onCheckIn"
          >
            <span v-if="!signed">Check in</span>
            <span v-else-if="checkInDayNum > 0 && checkInDayNum === progress.length">Tomorrow to Get More</span>
            <span v-else>Tomorrow Check in {{ tomorrowInfo?.prizeNum }}</span>
          </a>
        </div>
      </div>

      <!-- <div class="mt-10px mx-10px px-10px py-16px bg-#fff b-rd-2">
        <h3 class="text-18px text-#323435 font-bold">
          Tasks
        </h3>

        <div
          v-for="(item, index) in tasks"
          :key="index"
          class="flex justify-between items-center py-16px px-12px border-b-solid border-b-1px border-b-#F3F5FA last:border-b-0"
        >
          <div>
            <div class="text-#2D3945 font-medium">
              {{ item.taskTitle }}
            </div>
            <div class="text-#9875FB text-12px flex items-center mt-4px">
              <img class="w-14px h-14px" src="@/assets/welfare/welfare-gold.png">
              <span class="ml-1">{{ `+${item.coins}` }}</span>
            </div>
          </div>

          <div
            v-if="item.buttonState !== 1"
            class="w-60px text-center py-4px b-rd-15px text-#9875FB text-12px bg-#F3EEFF"
            @click="handleTask(item)"
          >
            {{ getBtnStatus(item) }}
          </div>
          <div v-else class="w-60px flex items-center justify-center">
            <i-assets-icon-right />
          </div>
        </div>
      </div> -->
    </div>

    <!-- <BasicGuidePopup v-model="showGuidePopup" :type="BasicGuidePopupAddHomeType" :arrow="BasicGuidePopupAddHomeArrow" /> -->

    <BasicModal v-model="modalSigned">
      <div class="p-5">
        <div class="text-17px lh-2em font-bold text-center">
          <p>Check in get <span class="text-#9875FB">{{ progressInfo?.prizeNum }}</span> Coins</p>
        </div>
        <a
          class="block mt-4 w-full h-9 lh-9 text-15px text-white text-center bg-primary rounded-full"
          @click="modalSigned = false"
        >
          Ok
        </a>
      </div>
    </BasicModal>

    <!-- <BasicModal v-model="finishPopup">
      <div class="p-5">
        <div class="text-17px lh-2em font-bold text-center">
          <p>get <span class="text-#9875FB">{{ coins }}</span> Coins</p>
        </div>
        <a
          class="block mt-4 w-full h-9 lh-9 text-15px text-white text-center bg-primary rounded-full"
          @click="finishPopup = false"
        >
          Ok
        </a>
      </div>
    </BasicModal> -->

    <BasicTabbar tab="rewards" />
  </div>
</template>

<style scoped>
.cover {
  background: url('@/assets/home-bg.png') no-repeat top left;
  background-size: 100% auto;
}
</style>
