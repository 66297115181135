<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useSubscript } from '@/composables/useSubscript'
import { VIP_TYPE } from '@/constants'

const { subscribeProducts, getUnitPrice, onSubscribeProducts } = useSubscript()
const active = ref(0)

const price = computed(() => {
  if (subscribeProducts.value && subscribeProducts.value[active.value])
    return subscribeProducts.value && subscribeProducts.value[active.value]?.price

  return null
})
</script>

<template>
  <div class="subscribe-cover">
    <BasicNavbar />

    <div class="px-20px">
      <div class="text-20px text-#323435 font-bold h-44px flex items-center justify-center">
        Membership
      </div>
      <div class="text-28px font-bold text-#9875FB mt-2">
        VIP Membership
      </div>
      <div class="text-#65686F text-14px">
        After activating the membership, you can earn more rewards each day during the validity period.
      </div>
      <div class="text-#9875FB mt-2px text-14px">
        You haven’ t joined VIP membership yet.
      </div>
    </div>

    <div class="member-wrap mt-2 mx-10px bg-#fff b-rd-2 box-shadow px-10px pt-16px">
      <div v-for="(item, index) in subscribeProducts" :key="index">
        <div
          v-if="item.isShow"
          class="flex items-center px-16px py-20px border-rd-8px text-#32353A relative mb-10px"
          :style="{
            border: active === index ? '1px solid #9875FB' : '1px solid #F3F5FA',
            background: active === index ? '#F3EFFF' : '#F3F5FA',
          }"
          @click="active = index"
        >
          <div v-if="item.subscribeType === VIP_TYPE.DISCOUNT" :class="`absolute top-0 right-0 font-bold text-31px  leading-[28px] ${active === index ? 'text-[#9875FB]' : 'text-[#C6D1ED]'}`">
            {{ item.cycleStr }}
            <span :class="` absolute top-0 right-0 w-110% h-120% ${active === index ? 'cycleBg' : 'cycleCom'}`" />
          </div>
          <div v-if="item.cornerTip && item.marketingCopy" class="popular-tag">
            {{ item.marketingCopy }}
          </div>
          <div class="flex-shrink-0">
            <img v-if="item.subscribeType === VIP_TYPE.DISCOUNT" src="@/assets/vip_discount.png" width="22">
            <img v-else src="@/assets/vip_icon2.png" width="22">
          </div>
          <div v-if="item.subscribeType !== VIP_TYPE.DISCOUNT" class="flex-1 ml-16px">
            <div class="text-18px font-medium">
              {{ item.cycleStr }}
            </div>
            <div class="text-#9875FB">
              {{ item.subscribeText }}
            </div>
          </div>
          <div v-else class="flex-1 ml-16px">
            <div class="text-18px font-medium">
              {{ item.discountCoins }} Coins
            </div>
            <ul class="text-#9875FB text-12px list-disc pl-5">
              <li>Get {{ item.discountOnceCoins }} Coins at once</li>
              <li>Earn {{ item.discountBonusCoins }} Coins ({{ item.discountDailyCoins }}/day) from daily login</li>
            </ul>
          </div>
          <div class="flex-shrink-0 text-right">
            <div class="text-18px font-bold">
              $ {{ item.price }}
            </div>
            <div v-if="getUnitPrice(item) && item.subscribeType !== VIP_TYPE.DISCOUNT" class="text-#65686F text-12px">
              $ {{ getUnitPrice(item) }}
            </div>
            <div v-else-if="item.subscribeType === VIP_TYPE.DISCOUNT && item.discountOriginalPrice" class="text-#65686F text-12px line-through">
              $ {{ item.discountOriginalPrice }}
            </div>
          </div>
        </div>
      </div>

      <div class="px-4px text-10px text-#9296A0 pb-80px">
        <p class="text-12px mt-6px">
          Tips:
        </p>
        <p class="mt-6px">
          1. After activating the membership, the specified number of coins for members will be credited to the account in one go. Additionally, members will receive extra daily coins each time they open the website or launch the app.
        </p>
        <p class="mt-6px">
          2. All the coins you have obtained through membership subscription will be cleared within seven days after the membership validity period ends.
        </p>
      </div>

      <div
        v-if="price"
        class="w-78% ml-11% h-40px bg-#9875FB text-#fff text-16px font-medium flex items-center justify-center border-rd-20px fixed bottom-20px left-0 right-0"
        @click="onSubscribeProducts(subscribeProducts[active])"
      >
        Buy now!  <span>$ {{ price }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.member-wrap {
  min-height: calc(100vh - 192px);
}

.subscribe-cover {
  width: 100%;
  background: url('@/assets/vip_bg.png') no-repeat top left;
  background-size: 100% auto;
}

.popular-tag {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 56px;
  height: 18px;
  background: linear-gradient(90deg, #FC5549 0%, #FA4F78 100%);
  border-radius: 8px 0px 12px 0px;
  font-weight: 400;
  font-size: 10px;
  color: #FFFFFF;
  text-align: center;
}
.cycleBg {
  background-image: linear-gradient(
    180deg,
    #bea9ff08,
    #f3effff2
  );
  font-family: HelveticaNeue-Bold;
}
.cycleCom{
  background-image: linear-gradient(
    180deg,
    #c6d1ed00,
    #F3F5FA
  );
  font-family: HelveticaNeue-Bold;
}
</style>
