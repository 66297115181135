import { createEnum } from './options'

// #region 订阅周期
/**
 * 订阅周期
 */
export const SubscriptionCycle = createEnum([
  { value: 6, key: 'OneDay', label: '1 Day', days: '1 Day' },
  { value: 7, key: 'ThreeDays', label: '3 Days', days: '3 Days' },
  { value: 1, key: 'WEEK', label: 'Weekly', days: '7 Days' },
  { value: 2, key: 'MONTH', label: 'Monthly', days: '30 Days' },
  { value: 3, key: 'SEASON', label: 'Seasonal', days: '90 Days' },
  { value: 4, key: 'HALF_YEAR', label: 'Semi-Annual', days: '182 Days' },
  { value: 5, key: 'YEAR', label: 'Annual', days: '365 Days' },
] as const)
// #endregion

// #region RECHARGE_TYPE_NAME
export const RECHARGE_TYPE_NAME = createEnum([
  { value: 1, key: 'Recharge', label: 'Recharge' },
  { value: 2, key: 'ChargeGive', label: 'Charge Give' },
  { value: 3, key: 'VIPCharge', label: 'VIP Charge' },
  { value: 4, key: 'SystemOperate', label: 'System Operate' },
  { value: 5, key: 'SystemCompensation', label: 'System Compensation' },
  { value: 6, key: 'ActivityCharge', label: 'Activity Charge' },
  { value: 7, key: 'ActivityChargeGive', label: 'Activity Charge Give' },
  { value: 8, key: 'ActivityGive', label: 'Activity Give' },
  { value: 9, key: 'SubscribeDeduct', label: 'Subscribe Deduct' },
  { value: 10, key: 'ExpireZero', label: 'Expire Zero' },
  { value: 11, key: 'CheckIn', label: 'Check In' },
  { value: 12, key: 'AD', label: 'AD' },
  { value: 13, key: 'Email', label: 'Email' },
  { value: 14, key: 'Subscription', label: 'subscription' },
  { value: 15, key: 'SubscriptionDiscount', label: 'subscription' },
] as const)
// #endregion

// #region TASK_TYPE
export enum TASK_TYPE {
  /** 绑定第三方账号 */
  BIND = 0,
  /** 添加到外部浏览器 */
  ADD_BROWSER = 1,
  /** 加桌 */
  ADD_TO_HOME_SCREEN = 2,
}
// #endregion
