<script lang="ts" setup>
import { ref } from 'vue'
import { POP_TYPE } from '@/constants'

defineProps<{
  value: boolean
  discountPayInfo: Record<string, any>
}>()

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'input', value: boolean): void
}>()
const countdownTime = ref<number>(3)
const timer = setInterval(() => {
  countdownTime.value--
  if (countdownTime.value < 0) {
    clearInterval(timer)
    handleClose()
  }
}, 1000)

function handleClose() {
  emit('close')
  emit('input', false)
}
</script>

<template>
  <BasicPopup :value="value" :pop-type="POP_TYPE.CENTER" @input="value => $emit('input', value)">
    <div v-if="value" class="w-266px min-h-237px rounded-12px basic-coins-sucess pt-22px pb-20px px-20px">
      <div class="text-white text-20px flex justify-center mb-16px">
        Congratulations!
      </div>
      <div class="w-full h-70px flex items-center">
        <div class="coins-lef-bg w-110px h-70px flex items-center px-20px ">
          <img class="w-20px h-20px" src="@/assets/coins-flag.png" alt="">
          <span class="text-[#FF873F] ml-4px text-20px font-bold">{{ discountPayInfo.discountOnceCoins }}</span>
        </div>
        <img class="w-20px h-20px absolute left-123px" src="@/assets/coins-center.png" alt="">
        <div class="coins-right-bg w-110px h-70px flex items-center justify-center px-20px  ml-10px">
          <img class="w-32px h-42px rounded-4px" :src="discountPayInfo.bookImageUrl" alt="">
        </div>
      </div>
      <div class="text-12px text-[#65686F] leading-16px break-all flex justify-center mt-12px">
        Free to Read This Book During
      </div>
      <div class="text-12px text-[#65686F] leading-16px break-all flex justify-center8">
        Membership Validity-No Coins Needed
      </div>
      <div class="flex items-center justify-center cursor-pointer mt-20px">
        <div class="w-127px py-10px px-19px bg-[#9875FB] flex justify-center text-white text-14px rounded-20px inline-block box-border" @click="handleClose">
          Ok <span v-if="countdownTime">({{ countdownTime }}s)</span>
        </div>
      </div>
    </div>
  </BasicPopup>
</template>

<style lang="scss" scoped>
.basic-coins-sucess {
    background-image: linear-gradient(180deg, #A778FE, #ffffff);
}
.coins-lef-bg {
    background: url('@/assets/coins-left.png') no-repeat;
    background-size: 100% 100%;
}
.coins-right-bg {
    background: url('@/assets/coins-right.png') no-repeat;
    background-size: 100% 100%;
}
</style>
